export const logo = require('../assets/images/home/logo.png').default
export const background = require('../assets/images/home/background.png').default
export const titleHome = require('../assets/images/home/title-home.png').default
export const titleGrant = require('../assets/images/home/title-grant.svg').default
export const titleMember = require('../assets/images/home/title-member.png').default
export const featureRight = require('../assets/images/home/feature-1.svg').default
export const featureLeft = require('../assets/images/home/feature-2.svg').default
export const memberMap = require('../assets/images/home/member.gif').default







